import React, { useState, useEffect } from 'react';
import "../styles/Blog.css";

export const Blog = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/blogs/blog-all`);
        if (response.ok) {
          const data = await response.json();
          setBlogs(data);
          console.log('Fetched blogs:', data);
        } else {
          console.error("Failed to fetch blogs");
        }
      } catch (err) {
        console.error("Error fetching blogs:", err.message);
      }
    };

    fetchBlogs();
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? blogs.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === blogs.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === blogs.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Change slide every 7 seconds 

    return () => clearInterval(interval);
  }, [blogs.length]);

  return (
    <div className="blogcontainer">
      <div className="blogtitle">
        <h1>Our Latest Blogs </h1>
        <p>Uncover Insider Tips and Inspiring Travel Stories on Our Blog. Let Our Content Ignite Your Wanderlust!</p>
      </div>
      <div className='blogview'>
        {blogs.map((d, index) => {
          let className = 'blogcard';
          if (index === currentIndex) {
            className += ' active';
          } else if (index === (currentIndex - 1 + blogs.length) % blogs.length) {
            className += ' left';
          } else if (index === (currentIndex + 1) % blogs.length) {
            className += ' right';
          }
          return (
            <div className={className} key={index}>
              <img src={d.imgUrl} alt={d.title} />
              <h2>{d.title}</h2>
              <p>{d.description}</p>
              <button>Read more</button>
            </div>
          );
        })}
        <button className="arrow left-arrow" onClick={handlePrevClick}>&lt;</button>
        <button className="arrow right-arrow" onClick={handleNextClick}>&gt;</button>
      </div>
    </div>
  );
};
